.navbar {
  font-weight: 600;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.nav-item {
  .dropdown-menu {
    border: none;
    margin-top: .5rem;
    border-top: 1px solid #f2f2f2 !important;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08);
  }
}

.nav-link {
  .svg-inline--fa {
    font-size: 1.4rem;
  }
}