@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '~sweetalert2/src/sweetalert2';
@import '~pretty-checkbox/src/pretty-checkbox.scss';
@import '~vue-select/src/scss/vue-select';
@import "~easy-circular-progress/src/index";

@import 'elements/card';
@import 'elements/navbar';
@import 'elements/buttons';
@import 'elements/transitions';
@import 'elements/badger-accordion';

body {
  background-color: transparent;
  overflow-x:hidden;
}

.main-layout {
  padding-bottom: 10px;
}

.logo-background {
  background-image: $logo-blue-with-text;
  background-repeat: no-repeat;
}

.angled-box-right {
  clip-path: polygon(
      0 0, /* left top */
      100% 0, /* right top */
      95% 100%, /* right bottom */
      0 100% /* left bottom */
  );

  border-radius: 0 !important;
}

.angled-box-left {
  clip-path: polygon(
      10% 0, /* left top */
      100% 0, /* right top */
      100% 100%, /* right bottom */
      0 100% /* left bottom */
  );
}

.before-splash {
  color: $text-grey;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: .15em; /* Adjust as needed */
}

.data-table {
  .card-header {
    display: none;
  }

  .card-body {
    padding: 0;
  }

  .table-active {
    td {
      white-space: nowrap;
    }
  }

  .table-responsive {
    height: 100%;
  }

  min-height: 770px;
}

.data-table-no-height {
  .card-header {
    display: none;
  }

  .card-body {
    padding: 0;
  }

  .table-active {
    td {
      white-space: nowrap;
    }
  }

  .table-responsive {
    height: 100%;
  }
}

.data-table-no-height-with-header {
  .card-body {
    padding: 0;
  }

  .table-active {
    td {
      white-space: nowrap;
    }
  }

  .table-responsive {
    height: 100%;
  }
}

.no-footer {
  .card-footer {
    display: none;
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px !important;
  top: 22px !important;
  cursor: pointer;
}

.bm-burger-bars {
  background-color: $light-grey !important;
}

.line-style {
  position: absolute;
  height: 15% !important;
  left: 0;
  right: 0;
}

.cross-style {
  position: absolute;
  top: 12px;
  right: 2px;
  cursor: pointer;
}

.bm-cross {
  background: #000000 !important;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-menu {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background-color: $light-grey !important;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 36px !important; /* Place content 60px from the top */
  transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/

  hr {
    margin: 5px 0;
    padding: 0;
  }
}

.bm-overlay {
  background: none !important;
}

.bm-item-list {
  color: #b8b7ad;
  margin-left: 10%;
  font-size: 20px;
}

.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.7em;
}

.bm-item-list > * > span {
  margin-left: 10px;
  font-weight: 700;
  color: white;
}

.loading-spinner {
  pointer-events: none;

  .fa-spinner {
    animation: spinAround 800ms infinite linear;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
  }
}

.nowrap {
  white-space: nowrap;
}

table.no-head > thead {
  display: none;
}

.custom-control-label::after {
  cursor: pointer;
}

.subject-status {
  white-space: nowrap;
  //text-decoration: underline;
  font-weight: bold;
}

.v-select {
  &.form-control {
    border: none;
    padding: 0 !important;
  }

  &.is-invalid {
    background-image: none;

    .dropdown-toggle {
      border: 1px solid #dc3545;
    }
  }
}

.form-group.required .col-form-label:after {
  content:"*";
  color:red;
}

.pretty.is-invalid {
  .state label::before {
    border-color: #dc3545;
  }
}

.custom-file.is-invalid {
  .custom-file-label {
    border-color: #dc3545;
  }
}

.v--modal-overlay .v--modal-box {
  overflow: visible !important;
}

.level-min-width {
  min-width: 85px;
}

/*************************************/
/*          Animations           */
/*************************************/

@keyframes spinAround {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

/*************************************/
/*          Media Queries            */
/*************************************/

@include media-breakpoint-down(md) {
  .angled-box-right {
    clip-path: polygon(
        0 0, /* left top */
        100% 0, /* right top */
        90% 100%, /* right bottom */
        0 100% /* left bottom */
    );
  }

  .angled-box-left {
    clip-path: polygon(
        20% 0, /* left top */
        100% 0, /* right top */
        100% 100%, /* right bottom */
        0 100% /* left bottom */
    );
  }
}
