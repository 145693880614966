.page-enter-active,
.page-leave-active {
  transition: 1s;
}

.page-enter {
  transform: translate(100%, 0);
}

.page-leave-to {
  transform: translate(-200%, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .15s
}
.fade-enter,
.fade-leave-to {
  opacity: 0
}

.login-enter-active,
.login-leave-active {
  transition: 1s;
}

.login-enter {
  transform: translate(0, 300%);
}

.login-leave-to {
  transform: translate(0, 300%);
}
